<template>
  <div>
    <div class="screen-wrapper">
      <div class="screen" :style="{ backgroundImage: `url(${imageUrl})` }">
        <div class="screen-text">
          <h1>{{ title }}</h1>
          <div class="action">
            <p>{{ promotion }}</p>
            <br />
            <button
              class="btn-play"
              v-if="regristrationPhone"
              @click="directBilling('regModal')"
              href="JavaScript:void(0);"
            >
              {{ buttonText }}
            </button>
          </div>
          <footer>
            <p>{{ disclaimer }}</p>
          </footer>
        </div>
      </div>
    </div>
    <div class="modal-background" id="chargingError">
      <div class="modal-content">
        <span class="close" onclick="hideChargingError('chargingError')"></span>
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const publicIp = require("public-ip");

export default {
  props: {
    URL: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      imageUrl: this.URL,
      title: "Play Unlimited Games in xoshplay",
      promotion: "special promotion available",
      buttonText: "Play Now",
      disclaimer: "*Charge 5 Taka + VAT, SD, SC (on demand)",
      host: null,
      error: false,
      message: null,
      regristrationPhone: "",
      button: true,
      robiBilling: true,
      individualTransactionId: 0,
      disabled: true,
      ip: null
    };
  },
  async created() {
    try {
      const msisdn_get_url_for_robi = "http://www.robimsisdn.xoshplay.com";

      var msisdn_data = await axios.get(msisdn_get_url_for_robi, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      console.log("msisdn: ", msisdn_data.data.msisdn);
      this.regristrationPhone = msisdn_data.data.msisdn;
      let src =
        this.$route.query["src"] !== undefined ? this.$route.query.src : "";
      this.$root.mainWrapper = "wrapper-main-landing";
      this.host = this.$root.url();
      this.ip = await publicIp.v4();
      console.log("ip", this.ip);
      const path = "campaign";
      const url = `${this.host}/${path}?ip=${this.ip}&opt=3&src=${src}`;
      await axios.get(url);
      return true;
    } catch (e) {
      return false;
    }
  },
  methods: {
    async directPackagePurchase() {
      try {
        this.buttonText = "Please wait few seconds";
        this.robiBilling = false;
        let vm = this;
        let directBilling = "ok";
        let otp = "1234";
        let phone = this.regristrationPhone;
        let name = "XYZ";
        let password = "unknown";
        let payment = 3;
        this.individualTransactionId = this.referenceId(phone);
        const path = "otp";
        const url = `${this.host}/${path}?name=${name}&phone=${phone}&password=${password}&otp=${otp}&ip=${this.ip}&directBilling=${directBilling}&payment=${payment}&individualTransactionId=${this.individualTransactionId}&type=promotional`;
        let response = await axios.get(url);
        this.robiBilling = true;
        this.buttonText = "Play Now";
        if (
          response.data.success === true &&
          response.data.chargingStatus === true
        ) {
          localStorage.clear();
          localStorage.setItem("phone", response.data.phone);
          this.$root.mainWrapper = "wrapper-main";
          this.$router.push({ name: "Home" });
        } else if (
          response.data.success === true &&
          response.data.chargingStatus === false
        ) {
          let errorObject = {
            status: false,
            message: "Charging failed,please try again later"
          };
          this.errorMessage(errorObject);
          this.showModal("chargingError");
          localStorage.clear();
          localStorage.setItem("phone", response.data.phone);
          setTimeout(function() {
            vm.$router.push({ name: "Home" });
            vm.$root.mainWrapper = "wrapper-main";
          }, 4000);
          return true;
        } else {
          this.robiBilling = true;
          let errorObject = {
            status: false,
            message: "Something wrong,please try again later"
          };
          this.errorMessage(errorObject);
          this.showModal("chargingError");
          return true;
        }
      } catch (e) {
        this.robiBilling = true;
        this.buttonText = "Play Now";
        let errorObject = {
          status: false,
          message: "Something wrong,please try again later"
        };
        this.errorMessage(errorObject);
        this.showModal("chargingError");
        return true;
      }
    },
    errorMessage(error) {
      this.error = error.status;
      this.message = error.message;
    },
    hideModal(id) {
      $("#" + id).fadeOut(400);
      $("body").toggleClass("no-scroll");
    },
    showModal(id) {
      $("#" + id).fadeIn(200);
      $("body").toggleClass("no-scroll");
    },
    async directBilling(id) {
      if (this.regristrationPhone !== "") {
        await this.directPackagePurchase();
        return true;
      }
      this.showModal(id);
      return true;
    },
    referenceId(phone) {
      let curTime = Date.now();
      let randomNumber = Math.floor(100000000 + Math.random() * 900000000);
      return curTime + phone + randomNumber;
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.screen {
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  text-align: center;
  position: relative;
}
.screen::after {
  content: "";
  display: block;
  width: 100%;
  height: 70%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    359.71deg,
    rgba(0, 0, 0, 0.5) 46.08%,
    rgba(0, 0, 0, 0) 93.08%
  );
  z-index: 1;
}
.screen-wrapper {
  max-width: 450px;
  margin: 0 auto;
}
.screen-text {
  position: relative;
  z-index: 5;
  padding: 0 15px;
}
.screen-text h1 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 39px;
  line-height: 50px;
  letter-spacing: -0.04em;
  color: #ffffff;
}
.screen-text .action {
  margin: 50px 0;
}
.screen-text .action p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #ffffff;
}
.screen-text .action .btn-play {
  padding: 10px 50px;
  background: #0186fc;
  border-radius: 6px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.screen-text .action .btn-play:hover {
  background: #046bc5;
}
.screen-text footer {
  margin-bottom: 25px;
}
.screen-text footer p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #ffffff;
  margin: 0;
}
</style>
